<template>
  <div class="max-w-screen-xl mx-auto w-full sm:mt-12 sm:mb-16" :class="{ 'sm:mb-30': isEditMode }">
    <div class="flex flex-col-reverse xl:flex-row gap-8 mx-6 sm:mx-auto pt-6">
      <div class="flex-1 w-full xl:w-2/3">
        <div class="rounded bg-white shadow-drop px-3 py-4 md:px-8 md:py-6">
          <h3 class="relative pb-7 sm:pb-5 w-full flex items-center justify-between">
            Info
            <button
              type="button"
              v-if="!isEditMode && isGroupAdmin"
              @click="isEditMode = true"
              class="edit-btn flex gap-1 text-sm group"
            >
              <EditIcon
                :class="{ 'default-color': isColorDefault() }"
                class="w-5 h-5 cursor-pointer animation-focus-default"
              />
              <span class="group-hover:text-orange-400">Edit</span>
            </button>

            <CloseIcon
              v-if="isEditMode && isGroupAdmin"
              @click="isEditMode = false"
              :class="{ 'default-color': isColorDefault() }"
              class="edit-btn w-5 h-5 cursor-pointer animation-focus-default"
            />
          </h3>

          <TextInput
            v-if="isEditMode"
            v-model="eventObject.name"
            class="border-gray-300 mb-6 sm:mb-8"
            label="Name event"
            placeholder="Name event"
            :invalid="!eventObject.name || !eventObject.name.length"
          ></TextInput>

          <div
            v-if="isEditMode"
            class="w-full pb-8 sm:pb-12 sm:mb-12 sm:border-b sm:border-gray-300"
          >
            <VueEditor
              v-model="eventObject.description"
              ref="description"
              class="admin-view-editor bg-white shadow-drop border-gray-300 text-lg text-gray-900 font-sans rounded w-full lg:w-full"
              placeholder="Write an event description"
              :editorToolbar="isMobile ? customToolbarMobile : customToolbar"
            ></VueEditor>
          </div>

          <VueEditor
            v-if="!isEditMode && eventObject.description"
            :editorOptions="{ modules: { toolbar: false } }"
            :disabled="true"
            :class="{
              'h-auto': !showReadMore,
              'h-150px': showReadMore,
              textWrapperShadow: isReadMore,
            }"
            class="disabled-text-editor pb-4 overflow-hidden text-sans border-none"
            v-model="eventObject.description"
          />

          <div
            @click="toggleReadMore"
            v-if="!isEditMode && showReadMore"
            class="flex items-center cursor-pointer w-auto"
          >
            <ChevronUpIcon :class="{ down: isReadMore }" class="chevron-icon w-3 mr-2" />
            <div class="text-sm font-semibold">
              {{ isReadMore ? 'Read more' : 'Read less' }}
            </div>
          </div>

          <div v-if="isEditMode" class="block pb-10 sm:pb-6 lg:pb-4 lg:mb-0 w-full">
            <label class="text-sm font-semibold text-gray-800">Start</label>
            <div class="input-container">
              <CalendarIconMobile class="block w-6 h-6 mr-4" />
              <CalendarInput
                class="calendar h-11 w-1/3"
                placeholder="Choose date..."
                :custom-height="true"
                :show-chevron="false"
                v-model="eventDateStart"
                :localStringFormat="timeLocalFormat"
                compact
                :dateRestrictFunction="notBeforeToday"
              />
            <div class="mb-0 flex items-center sm:ml-4">
              <ClockIconMobile class="block w-6 h-6 mr-4" />
              <div class="flex w-full">
                <DropdownAutocomplete
                  :custom-height="true"
                  :options="times"
                  class="h-11 mr-2"
                  v-model="startTime"
                  :value="startTime"
                  placeholder="Begins at..."
                  compact
                />
              </div>
            </div>
            </div>
            <label class="text-sm font-semibold text-gray-800" >End</label>
            <div class="input-container">
              <CalendarIconMobile class="block w-6 h-6 mr-4" />
              <CalendarInput
                class="calendar h-11 w-1/3"
                placeholder="Choose date..."
                :custom-height="true"
                :show-chevron="false"
                v-model="eventDateEnd"
                :localStringFormat="timeLocalFormat"
                compact
                :dateRestrictFunction="notBeforeToday"
              />
              <div class="mb-0 flex items-center sm:ml-4">
              <ClockIconMobile class="block w-6 h-6 mr-4" />
                <div class="flex w-full">
                  <DropdownAutocomplete
                    :custom-height="true"
                    :options="times"
                    class="h-11  mr-2"
                    v-model="endTime"
                    :value="endTime"
                    placeholder="Ends at..."
                    compact
                  />
                </div>
              </div>
            </div>
            <div class="input-container">
              <TicketIconMobile class="block w-6 h-6 mr-4" />
              <div class="flex w-full">
                <TextInputCompact
                  v-model="eventObject.ticketPrice"
                  placeholder="Ticket price"
                  class="h-12"
                  type="number"
                  :max-length="priceMaxLength"
                  :symbolPrefix="'$'"
                />
              </div>
            </div>
            <div class="px-5 py-4 mb-4 rounded bg-gray-100">
              <div class="flex justify-between items-center">
                <p class="text-gray-900 text-base font-semibold leading-[140%]">Payment Links</p>
              </div>

              <div v-for="provider in providers" :key="provider.id" class="mt-6 lg:mt-8">
                <div class="flex items-center text-gray-800 font-semibold text-sm mb-2">
                  {{ provider.name }}
                  <IconQuestion
                    v-if="provider.helpLink"
                    v-tooltip="{
                      content: generateTooltipContent(provider.helpLink),
                      autoHide: false,
                    }"
                    class="alert-icon"
                  />
                </div>
                <div class="flex gap-4 items-center">
                  <div class="flex justify-center items-center w-10 h-10 shrink-0 rounded">
                    <img :src="provider.image" alt="provider" />
                  </div>
                  <div class="relative w-full">
                    <TextInput
                      placeholder="Enter Link"
                      custom-height="h-[44px]"
                      v-model="editPaymentLinks[provider.name].url"
                      @focus="clearError"
                    >
                      <template v-slot:icon>
                        <TicketIcon class="ticket-icon w-5 h-5" />
                      </template>
                    </TextInput>
                    <span
                      v-if="errorObject && errorObject.providers.includes(provider.name)"
                      class="error-message"
                    >
                      {{ errorObject.message }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-container">
              <UsersIcon class="block w-6 h-6 mr-4" />
              <div class="flex items-center rounded bg-gray-200 w-full h-11 p-1">
                <input
                  class="hidden event-type-radio"
                  id="radio-1"
                  :disabled="saving"
                  value="1"
                  v-model="eventTypeId"
                  type="radio"
                />
                <label class="mr-2 w-1/2 animation-focus-default" for="radio-1">
                  <span>In-Person</span>
                </label>

                <input
                  class="hidden event-type-radio"
                  id="radio-2"
                  :disabled="saving"
                  value="2"
                  v-model="eventTypeId"
                  type="radio"
                />
                <label class="ml-2 w-1/2 animation-focus-default" for="radio-2">
                  <span>Virtual</span>
                </label>
              </div>
            </div>
            <div class="input-container sm:mb-8">
              <LocationLargeIcon v-if="+eventTypeId === 1" class="block w-6 h-6 mr-4" />
              <LinkLargeIcon v-if="+eventTypeId === 2" class="block w-6 h-6 mr-4" />
              <div v-if="+eventTypeId === 1" class="flex w-full">
                <MapsAutocompleteInput
                  class="maps-event-input"
                  :search-types="'establishment'"
                  custom-height
                  id="Location"
                  placeholder="Location"
                  :value="eventObject.location"
                  @updatePlaceAndAddress="handleUpdateFullAddress"
                  allowCustomEntries
                />
              </div>
              <div v-if="+eventTypeId === 2" class="flex w-full">
                <TextInputCompact v-if="isEditMode" v-model="eventObject.url" placeholder="Link" />
              </div>
            </div>
          </div>

          <div v-if="!isEditMode" class="flex flex-col sm:flex-row sm:mt-8">
            <div class="event-information-block mb-4 sm:mb-0 flex-grow">
              <div class="flex items-center sm:items-start sm:flex-col">
                <CalendarIcon class="hidden sm:block event-information-icon w-6 mb-4 mx-auto" />
                <CalendarIconMobile class="block sm:hidden event-information-icon w-6 mx-auto" />

                <div class="hidden sm:block text-sm font-semibold text-center">
                  {{ eventWeekDay }} <br v-if="eventWeekDay" />
                  {{ eventDateFormatted ? eventDateFormatted : 'TBD' }}
                </div>
                <div class="sm:hidden ml-4 text-base font-semibold text-center">
                  {{ eventDateFormattedMobile ? eventDateFormattedMobile : 'TBD' }}
                </div>
              </div>
            </div>

            <div
              class="event-information-block relative sm:ml-6"
              v-if="currentEvent.ticketPrice || currentEvent.paymentLinks.length"
            >
              <div class="flex sm:flex-col">
                <TicketIcon class="event-information-icon hidden sm:block w-6 h-5 mb-4 mx-auto" />
                <TicketIconMobile class="event-information-icon block sm:hidden w-6 h-5 mx-auto" />
                <div
                  v-if="currentEvent.ticketPrice"
                  class="text-sm font-semibold text-center ml-4 sm:ml-0"
                >
                  ${{ currentEvent.ticketPrice }}
                </div>

                <p
                  class="py-2 text-gray-800 text-center text-sm font-normal leading-[140%] underline"
                >
                  Buy tickets with:
                </p>
                <div class="flex justify-center gap-2 pb-4">
                  <a
                    class="w-[36px] h-[36px]"
                    v-for="link in currentEvent.paymentLinks"
                    :href="link.url"
                    target="_blank"
                    :key="link.provider"
                  >
                    <img :src="link.image" alt="payment" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="
              !isEditMode &&
              ((+eventTypeId === 2 && eventObject.url) ||
                (+eventTypeId === 1 && eventObject.location))
            "
            class="flex justify-start sm:justify-center px-6 py-6 rounded border border-gray-300 mt-4 sm:mt-6 flex-grow"
          >
            <div v-if="+eventTypeId === 1" class="flex items-center">
              <LocationIcon class="w-6 sm:w-5 mr-4"></LocationIcon>
              <a class="text-sm font-bold">
                {{ eventObject.location }}
              </a>
            </div>
            <div v-if="+eventTypeId === 2" class="flex items-center cursor-pointer underline">
              <GlobeIcon class="w-6 sm:w-5 mr-4" />
              <a class="text-sm font-bold" @click="goToLink(eventObject.url)">
                {{ eventObject.url }}
              </a>
            </div>
          </div>

          <div v-if="isEditMode" class="flex gap-2 justify-end">
            <Button class="px-[15px] w-[120px]" type="text" @onClick="showDeleteEventDialog = true">
              Delete
            </Button>
            <Button
              class="px-[20px] sm:w-[120px]"
              compact
              :primary-full-size="false"
              type="primary"
              @onClick="formatAndSendUpdate"
            >
              Save
            </Button>
          </div>
        </div>

        <div v-if="currentEvent.discussionEnabled || isGroupAdmin" class="mt-6">
          <EventDiscussion
            :is-hidden="!currentEvent.discussionEnabled"
            :is-edit-mode="isEditMode"
            @toggleDiscussion="toggleDiscussionActive"
            :discussion-enable="discussionEnable"
          />
        </div>
      </div>

      <div
        class="w-full xl:w-1/3 xl:min-w-276px rounded bg-white shadow-drop pt-6 pb-8 px-6 max-h-full xl:max-h-[640px] overflow-y-auto static xl:sticky xl:top-24"
      >
        <div class="sm:flex xl:block flex-wrap gap-4 justify-between">
          <h4 class="font-bold text-gray-900 pb-5 hidden xl:block">RSVP</h4>
          <div
            class="w-full flex items-center justify-center self-stretch gap-2.5 p-1 md:p-2 mb-2 md:mb-3 rounded-lg bg-gray-100 order-1 xl:order-none"
          >
            <RadioButtonStyled
              id="radio1"
              primary-color
              label="I'm Going!"
              value="1"
              v-model="rsvp"
              @change="chooseRsvp"
              class="w-full h-[44px] !px-2 border-none"
              label-class="!text-sm"
            />
            <RadioButtonStyled
              id="radio2"
              label="Unable to Attend"
              value="2"
              v-model="rsvp"
              @change="chooseRsvp"
              class="w-full h-[44px] !px-2 border-none"
              label-class="!text-sm"
            />
          </div>

          <div v-if="rsvp === IS_GOING" class="pt-1 md:pt-4 mb-1 md:mb-4 order-3 xl:order-none">
            <p class="text-black text-sm font-semibold leading-[140%]">
              I’m bringing additional guests with me
            </p>

            <div class="flex gap-2.5 py-1 md:py-4">
              <button
                type="button"
                class="attending_btn animation-focus-default"
                :class="{ attending_btn__active: guestsAmount === 1 }"
                @click.stop="updateRsvpWithGuests(1)"
              >
                +1
              </button>
              <button
                type="button"
                class="attending_btn animation-focus-default"
                :class="{ attending_btn__active: guestsAmount === 2 }"
                @click.stop="updateRsvpWithGuests(2)"
              >
                +2
              </button>
              <button
                type="button"
                class="attending_btn animation-focus-default"
                :class="{ attending_btn__active: guestsAmount === 3 }"
                @click.stop="updateRsvpWithGuests(3)"
              >
                +3
              </button>
              <button
                type="button"
                class="attending_btn animation-focus-default"
                :class="{ attending_btn__active: guestsAmount === 4 }"
                @click.stop="updateRsvpWithGuests(4)"
              >
                +4
              </button>
              <button
                type="button"
                class="attending_btn animation-focus-default"
                :class="{ attending_btn__active: guestsAmount === 5 }"
                @click.stop="updateRsvpWithGuests(5)"
              >
                +5
              </button>
            </div>
          </div>

          <div class="hidden xl:block border-t border-gray-300 pt-7">
            <div class="flex justify-between">
              <h3 class="pb-1">
                Attending <span class="text-sm">({{ eventObject.amountAttending }})</span>
              </h3>

              <Button
                type="text"
                class="semi-transparent attendees-btn h-10"
                :compact="true"
                @onClick="openAttendingModal"
              >
                <div class="text-gray-800 font-semibold text-sm">See All</div>
              </Button>
            </div>

            <div>
              <div
                v-for="person in attendeesSubset"
                :key="person.id"
                class="flex pt-4 justify-between items-center"
              >
                <div class="flex">
                  <div
                    class="photo-wrapper w-11 h-11 text-xs flex-shrink-0 overflow-hidden rounded-full"
                  >
                    <ProfilePhoto :url="person.profilePicture" />
                  </div>
                  <div class="my-auto ml-4 text-base font-semibold">
                    {{ person.firstName }} {{ person.lastName }}
                  </div>
                </div>
                <div v-if="person.guestsAmount">
                  <span
                    class="flex justify-center items-center gap-2.5 rounded px-2 py-0.5 text-gray-800 text-xs not-italic font-normal leading-[140%] bg-gray-200"
                  >
                    {{ person.guestsAmount > 1 ? `+${person.guestsAmount} Guests` : '+1 Guest' }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <Profiles-banner
            class="xl:hidden pb-3 sm:pb-0 border-b sm:border-0 border-gray-300 mb-0 md:mb-6 order-2 xl:order-none"
            :is-attending="isAttendingMode"
            @showAll="openAttendingModal"
            :max-profiles-count="maxProfilesCount"
            :profiles="attendeesSubset"
          />
        </div>
      </div>

      <div
        v-if="isGroupAdmin && isEditMode"
        class="fixed py-3 px-6 sm:py-4 top-app-header right-0 left-0 bg-white shadow w-full z-50"
      >
        <div class="flex justify-between items-center text-gray-100">
          <div
            class="hidden sm:flex items-center publish-link cursor-pointer animation-focus-default"
            @click="publishEvent"
          >
            <AlertInfoIcon v-if="!eventObject.isPublished" class="alert-icon w-5 h-5 mr-3" />
            <h6 v-if="!eventObject.isPublished">
              You are viewing a draft of your event. Click to Publish...
            </h6>
          </div>
          <div class="flex w-full sm:w-max justify-between items-center">
            <div class="flex items-center mr-8">
              <LoadingAnimation v-if="saving" class="loading-icon w-4 h-4 -mt-1" />
              <CheckIcon v-if="!saving && isSaved" class="saved-icon w-4 h-4 -mt-1" />
              <ForbiddenIcon v-if="!saving && !isSaved" class="error-icon w-4 h-4" />
              <span class="ml-2 w-max text-sm font-semibold">
                {{ saving ? 'Saving...' : isSaved ? 'Saved' : 'Not saved' }}
              </span>
            </div>
            <div class="flex">
              <Button
                v-if="!eventObject.isPublished"
                @onClick="publishEvent"
                compact
                class="publish-btn h-40px w-100px group"
                :class="{ 'default-color': isColorDefault() }"
                type="primary"
                color-custom
                :primary-full-size="false"
              >
                <span
                  :class="{
                    'text-defaultInteractive': isColorDefault(),
                    'text-primary': !isColorDefault(),
                  }"
                  class="text-sm group-hover:text-white transform"
                >
                  Publish
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <!--                     -->
      <!-- DIALOG DELETE EVENT -->
      <!--                     -->

      <transition name="component-fade" mode="out-in">
        <DialogDefault
          v-if="showDeleteEventDialog"
          :icon="'delete'"
          :header="'Delete event'"
          :text="'Are you sure you want to delete this event?'"
          :applyBtnText="'Delete'"
          @cancel="cancelDeleteEventDialog()"
          @apply="deleteEvent()"
        />
      </transition>

      <!--                  -->
      <!-- DIALOG ATTENDEES -->
      <!--                  -->

      <template v-if="showAttendingModal">
        <AttendeesModal
          :hideModal="closeAttendingModal"
          :rsvpYesList="attendees"
          :rsvpNoList="nonAttendees"
          :rsvpUnknownList="unknownAttendees"
          :isGroupAdmin="isGroupAdmin"
          @updateAttendees="updateAttendees"
        />
      </template>

      <!--                         -->
      <!-- DIALOG DISABLE COMMENTS -->
      <!--                         -->

      <transition name="component-fade" mode="out-in">
        <DialogDefault
          v-if="showDisableCommentsDialog"
          :icon="'comment'"
          :header="'Disable comments'"
          :text="'Are you sure you want to disable comments?'"
          :applyBtnText="'Disable'"
          @cancel="cancelDisableCommentsDialog()"
          @apply="disableComments()"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { DateTime } from 'luxon';
import { VueEditor } from 'vue2-editor';
import Button from '@/components/Button.vue';
import RadioButtonStyled from '@/components/baseComponents/RadioButtonStyled.vue';
import ProfilePhoto from '@/components/ProfilePhoto.vue';
import EventDiscussion from '@/components/events/EventDiscussion.vue';
import AttendeesModal from '@/components/events/AttendeesModal.vue';
import TextInputCompact from '@/components/baseComponents/TextInputCompact.vue';
import DropdownAutocomplete from '@/components/baseComponents/DropdownAutocomplete.vue';
import CalendarInput from '@/components/CalendarInput.vue';
import ChevronUpIcon from '@/assets/icon_chevron_up.svg';
import CheckIcon from '@/assets/icon_check_white.svg';
import GlobeIcon from '@/assets/icon_globe.svg';
import CalendarIcon from '@/assets/icon_calendar.svg';
import CalendarIconMobile from '@/assets/icon_calendar_large.svg';
import ClockIconMobile from '@/assets/icon_clock_extralarge.svg';
import AlertInfoIcon from '@/assets/icon_alert_info.svg';
import TicketIcon from '@/assets/icon_ticket.svg';
import LoadingAnimation from '@/assets/icon_spinning_circle.svg';
import ForbiddenIcon from '@/assets/icon_forbidden.svg';
import UsersIcon from '@/assets/icon_users_large.svg';
import TicketIconMobile from '@/assets/icon_ticket_large.svg';
import LocationIcon from '@/assets/icon_location.svg';
import CloseIcon from '@/assets/icon_close.svg';
import LocationLargeIcon from '@/assets/icon_location_large.svg';
import LinkLargeIcon from '@/assets/icon_link_large.svg';
import EditIcon from '@/assets/icon_edit.svg';
import ProfilesBanner from '@/components/ProfilesBanner.vue';
import TextInput from '@/components/TextInput.vue';
import DialogDefault from '@/components/DialogDefault.vue';
import MapsAutocompleteInput from '@/components/MapsAutocompleteInput.vue';
import { DEFAULT_COLOR } from '@/components/constants/groupColors';

import { timelineLabels, formatUrl } from '@/helpers';
import IconQuestion from '@/assets/icon_question.svg';
import { fetchPaymentProvidersService } from '@/services/global.service';

const IS_GOING = '1';
const WITHOUT_GUESTS = 0;

export default {
  name: 'GroupEvent',
  components: {
    IconQuestion,
    MapsAutocompleteInput,
    TextInput,
    ProfilesBanner,
    RadioButtonStyled,
    ProfilePhoto,
    EventDiscussion,
    AttendeesModal,
    TextInputCompact,
    DropdownAutocomplete,
    ChevronUpIcon,
    CheckIcon,
    CalendarInput,
    VueEditor,
    Button,
    GlobeIcon,
    CalendarIcon,
    CalendarIconMobile,
    ClockIconMobile,
    AlertInfoIcon,
    TicketIcon,
    LoadingAnimation,
    ForbiddenIcon,
    UsersIcon,
    TicketIconMobile,
    LocationIcon,
    LocationLargeIcon,
    LinkLargeIcon,
    EditIcon,
    CloseIcon,
    DialogDefault,
  },
  async created() {
    this.providers = await fetchPaymentProvidersService();

    this.fetchProfiles({
      groupSlug: this.$route.params.group,
    });
    const p1 = this.fetchGroupEvent({
      groupSlug: this.$route.params.group,
      eventId: this.$route.params.eventId,
    }).then((response) => {
      this.eventObject = response.groupEvent;
      this.discussionEnable = this.eventObject.discussionEnabled;
      if (this.eventObject.startDatetime) {
        const time = DateTime.fromISO(this.eventObject.startDatetime);
        this.startTime = time.toFormat('h:mm a');
      }
      if (this.eventObject.endDatetime) {
        const time = DateTime.fromISO(this.eventObject.endDatetime);
        this.endTime = time.toFormat('h:mm a');
      }
      this.eventTypeId = this.eventObject.eventType.id.toString();
      this.eventDateStart = this.eventDateStartUs;
      this.eventDateEnd = this.eventDateEndUs;

      if (this.providers.length) {
        const paymentProviderObject = {};
        this.providers.forEach((p) => {
          const activePayment = this.eventObject.paymentLinks.find(
            (payment) => payment.provider === p.id,
          );
          paymentProviderObject[p.name] = {
            provider: p.id,
            url: activePayment && activePayment.url ? activePayment.url : '',
          };
        });

        this.editPaymentLinks = paymentProviderObject;
      }

      setTimeout(() => {
        const editorContent = document.getElementsByClassName('ql-editor')[0];

        if (editorContent) {
          this.showReadMore = editorContent.scrollHeight > 150;
        }
      }, 500);
    });

    const p2 = this.fetchGroupEventAttendees({
      groupSlug: this.$route.params.group,
      eventId: this.$route.params.eventId,
    });

    Promise.all([p1, p2]).then(() => {
      if (this.eventObject.discussionEnabled) {
        this.fetchEventDiscussions({
          groupSlug: this.$route.params.group,
          eventId: this.$route.params.eventId,
        });
      }
      this.dataLoaded = true;
    });
  },
  data() {
    return {
      providers: [],
      guestsAmount: 0,
      editPaymentLinks: {},
      isEditMode: false,
      debounceMilliseconds: 1000,
      eventObject: {},
      startTime: '',
      endTime: '',
      eventDateStart: '',
      eventDateEnd: '',
      rsvp: null,
      eventTypeId: '',
      dataLoaded: false,
      attendingEnum: Object.freeze({
        attending: 'Attending',
        unableToAttend: 'Unable to Attend',
      }),
      showAttendingModal: false,
      customToolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['clean'],
        ['link'],
      ],
      customToolbarMobile: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['clean'],
      ],
      saving: false,
      isSaved: true,
      showReadMore: false,
      isReadMore: true,
      maxProfilesCount: 5,
      isAttendingMode: true,
      priceMaxLength: 15,
      showDisableCommentsDialog: false,
      discussionEnable: false,
      showDeleteEventDialog: false,
      defaultColor: DEFAULT_COLOR,
      atendeesForceUpdate: 0,
      IS_GOING,
    };
  },

  methods: {
    ...mapActions([
      'fetchGroupEvent',
      'fetchGroupEventAttendees',
      'postRsvp',
      'updateEvent',
      'fetchEventDiscussions',
      'deleteGroupEvent',
      'updateEventPhoto',
      'fetchProfiles',
      'clearGlobalErrors',
    ]),
    generateTooltipContent(link) {
      return `<p class="text-white text-center"><a class="text-blue-500 underline" href="${link}">Click here</a> for steps on <br /> how to get your payment link.</p>`;
    },
    clearError() {
      this.clearGlobalErrors();
    },
    isValidPaymentLinks() {
      let isValid = true;
      const urlPattern = /(https?:\/\/)?([\da-z\.-]+)\.([a-z]{2,6})([\/\w\.-]*)*\/??(?:&?[^=&]*=[^=&]*)*/;
      const providersKeys = [];

      for (const [key, value] of Object.entries(this.editPaymentLinks)) {
        if (value.url && !urlPattern.test(value.url)) {
          providersKeys.push(key);
        } else if (value.url) {
          value.url = formatUrl(value.url);
        }
      }

      if (providersKeys.length) {
        this.$store.commit('UPDATE_GLOBAL_ERRORS', [
          {
            fieldName: 'Payment Links',
            providers: providersKeys,
            message: 'Payment link should be a valid URL',
          },
        ]);
        isValid = false;
      }

      return isValid;
    },
    updateAttendees() {
      this.fetchGroupEventAttendees({
        groupSlug: this.$route.params.group,
        eventId: this.$route.params.eventId,
      });
    },
    isColorDefault() {
      return this.group.primaryColor === this.defaultColor;
    },
    chooseRsvp(rsvp) {
      this.rsvp = rsvp;
      this.postRsvp({
        groupSlug: this.$route.params.group,
        eventId: this.$route.params.eventId,
        rsvp,
      }).then(() => {
        this.fetchGroupEventAttendees({
          groupSlug: this.$route.params.group,
          eventId: this.$route.params.eventId,
        });
      });
    },
    updateRsvpWithGuests(guests) {
      this.guestsAmount = this.guestsAmount !== guests ? guests : WITHOUT_GUESTS;
      this.postRsvp({
        groupSlug: this.$route.params.group,
        eventId: this.$route.params.eventId,
        rsvp: this.rsvp,
        guests: this.userRsvp.guestsAmount !== guests ? guests : WITHOUT_GUESTS,
      }).then(() => {
        this.fetchGroupEvent({
          groupSlug: this.$route.params.group,
          eventId: this.$route.params.eventId,
        }).then((response) => {
          this.eventObject = response.groupEvent;
        });
        this.fetchGroupEventAttendees({
          groupSlug: this.$route.params.group,
          eventId: this.$route.params.eventId,
        });
      });
    },
    cancelDeleteEventDialog() {
      this.showDeleteEventDialog = false;
    },
    closeAttendingModal() {
      document.body.style.overflow = 'auto';
      this.showAttendingModal = false;
      this.fetchGroupEventAttendees({
        groupSlug: this.$route.params.group,
        eventId: this.$route.params.eventId,
      });
    },
    openAttendingModal() {
      document.body.style.overflow = 'hidden';
      this.showAttendingModal = true;
    },
    publishEvent() {
      this.eventObject.isPublished = true;
      this.formatAndSendUpdate();
    },
    deleteEvent() {
      this.deleteGroupEvent({
        groupSlug: this.$route.params.group,
        eventId: this.$route.params.eventId,
      }).then(() => {
        this.$router.push({
          name: 'description',
          params: {
            group: this.$route.params.group,
          },
        });
      });
    },
    formatAndSendUpdate() {
      this.saving = true;
      this.isSaved = false;

      const startDate = DateTime.fromFormat(this.eventDateStart, 'D');
      const endDate = DateTime.fromFormat(this.eventDateEnd, 'D');

      let startDatetime = startDate;
      let endDatetime = endDate;
      if (this.startTime) {
        const time = DateTime.fromFormat(this.startTime, 'h:m a');
        startDatetime = startDate
          .plus({
            hours: time.hour,
            minutes: time.minute,
          })
          .toISO();
      } else {
        startDatetime = startDate.toISO();
      }

      if (this.endTime) {
        const time = DateTime.fromFormat(this.endTime, 'h:m a');
        endDatetime = endDate
          .plus({
            hours: time.hour,
            minutes: time.minute,
          })
          .toISO();
      } else {
        endDatetime = endDate.toISO();
      }

      if (!this.isValidPaymentLinks()) {
        this.saving = false;
        this.isSaved = false;
        return;
      }

      const paymentLinks = [];

      for (const [key, value] of Object.entries(this.editPaymentLinks)) {
        if (value.url) {
          paymentLinks.push(this.editPaymentLinks[key]);
        }
      }

      const eventObjectToSend = {
        name: this.eventObject.name,
        description: this.eventObject.description,
        ticketPrice: this.eventObject.ticketPrice || null,
        paymentLinks,
        isPublished: this.eventObject.isPublished,
        discussionEnabled: this.eventObject.discussionEnabled,
        isDeleted: this.eventObject.isDeleted,
        eventType: this.eventTypeId,
        startDatetime,
        endDatetime,
        location: this.eventObject.location,
        url: this.eventObject.url,
      };
      this.updateEvent({
        groupSlug: this.$route.params.group,
        eventId: this.$route.params.eventId,
        groupEvent: eventObjectToSend,
      }).then(
        () => {
          // it saves too fast so delay it some
          setTimeout(() => {
            this.saving = false;
            this.isSaved = true;
            this.isEditMode = false;
            window.scrollTo(0, 0);
          }, 500);
        },
        () => {
          this.saving = false;
          this.isSaved = false;
        },
      );
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    toggleDiscussionActive() {
      if (this.discussionEnable) {
        this.eventObject.discussionEnabled = true;
        this.formatAndSendUpdate();
      } else {
        this.showDisableCommentsDialog = true;
      }
    },
    toggleReadMore() {
      this.isReadMore = !this.isReadMore;
      const editorContent = document.getElementsByClassName('quillWrapper')[0];

      if (editorContent) {
        editorContent.style.height = this.isReadMore ? '150px' : 'auto';
      }
    },
    disableComments() {
      this.eventObject.discussionEnabled = false;
      this.showDisableCommentsDialog = false;
      this.formatAndSendUpdate();
    },
    cancelDisableCommentsDialog() {
      this.discussionEnable = true;
      this.showDisableCommentsDialog = false;
    },
    handleUpdateFullAddress(address) {
      this.eventObject.location = address;
    },
    goToLink(link) {
      window.open(formatUrl(link));
    },
  },
  watch: {
    userRsvp(newValue) {
      this.guestsAmount = newValue.guestsAmount;
      if (this.userRsvp.rsvp === null) {
        this.rsvp = null;
      } else {
        this.rsvp = this.userRsvp.rsvp === this.attendingEnum.attending ? '1' : '2';
      }
    },
  },
  computed: {
    ...mapGetters([
      'currentEvent',
      'isLoading',
      'attendeesList',
      'user',
      'group',
      'profiles',
      'globalErrors',
      'eventDiscussions',
    ]),
    errorObject() {
      return this.globalErrors.find((el) => el.fieldName === 'Payment Links') || null;
    },
    isMobile() {
      return document.body.clientWidth <= 576;
    },
    isGroupAdmin() {
      const currentUser = this.profiles.slice().filter((member) => member.id === this.user.id)[0];
      return (
        (currentUser && currentUser.isGroupAdmin) ||
        (this.user && this.user.user && this.user.user.isSuperuser)
      );
    },
    attendeesSubset() {
      return this.attendees && this.attendees.length > 7
        ? this.attendees.slice(0, 7)
        : this.attendees;
    },
    attendees() {
      return this.attendeesList.filter((x) => {
        return x.rsvp === this.attendingEnum.attending;
      });
    },
    nonAttendees() {
      return this.attendeesList.filter((x) => {
        return x.rsvp === this.attendingEnum.unableToAttend;
      });
    },
    unknownAttendees() {
      return this.attendeesList.filter((x) => {
        return x.rsvp === null;
      });
    },
    userRsvp() {
      return this.attendeesList.filter((x) => {
        return x.id === this.user.id;
      })[0];
    },
    eventDateStartUs() {
      if (this.currentEvent && this.currentEvent.startDatetime) {
        const startDateTime = DateTime.fromISO(this.currentEvent.startDatetime);
        return startDateTime.toLocaleString({ locale: 'en-us' });
      }
      return '';
    },
    eventDateEndUs() {
      if (this.currentEvent && this.currentEvent.endDatetime) {
        const endDatetime = DateTime.fromISO(this.currentEvent.endDatetime);
        return endDatetime.toLocaleString({ locale: 'en-us' });
      }
      return '';
    },
    eventDateFormatted() {
      if (this.currentEvent && this.currentEvent.startDatetime && this.currentEvent.endDatetime) {
        const startDateTime = DateTime.fromISO(this.currentEvent.startDatetime);
        const endDateTime = DateTime.fromISO(this.currentEvent.endDatetime);

        if (startDateTime.toLocaleString(DateTime.DATE_SHORT) !== endDateTime.toLocaleString(DateTime.DATE_SHORT)) {
          return `${startDateTime.toLocaleString(DateTime.DATE_FULL)}, ${this.startTimeFormatted} - ${endDateTime.toLocaleString(DateTime.DATE_FULL)}, ${this.endTimeFormatted}`;
        }
        return `${startDateTime.toLocaleString(DateTime.DATE_FULL)}, ${this.startTimeFormatted} - ${this.endTimeFormatted}`;
      }
      return '';
    },
    eventDateFormattedMobile() {
      if (this.currentEvent && this.currentEvent.startDatetime) {
        const startDateTime = DateTime.fromISO(this.currentEvent.startDatetime);
        return `${startDateTime.toLocaleString(DateTime.DATE_HUGE)}, ${this.startTimeFormatted}`;
      }
      return '';
    },
    eventWeekDay() {
      if (this.currentEvent && this.currentEvent.startDatetime && this.currentEvent.endDatetime) {
        const startDateTime = DateTime.fromISO(this.currentEvent.startDatetime);
        const endDateTime = DateTime.fromISO(this.currentEvent.endDatetime);

        if (startDateTime.toLocaleString(DateTime.DATE_SHORT) !== endDateTime.toLocaleString(DateTime.DATE_SHORT)) {
          return `${startDateTime.weekdayLong} - ${endDateTime.weekdayLong}`;
        }

        return startDateTime.weekdayLong;
      }
      return 'd';
    },
    eventTimeFormatted() {
      if (this.startTimeFormatted && this.endTimeFormatted) {
        return `${this.startTimeFormatted} - ${this.endTimeFormatted}`;
      }
      if (this.startTimeFormatted) {
        return this.startTimeFormatted;
      }
      return '';
    },
    startTimeFormatted() {
      if (this.currentEvent && this.currentEvent.startDatetime) {
        const startDateTime = DateTime.fromISO(this.currentEvent.startDatetime);
        return startDateTime.toLocaleString({
          hour: 'numeric',
          minute: '2-digit',
          timeZoneName: 'short',
        });
      }
      return null;
    },
    endTimeFormatted() {
      if (this.currentEvent && this.currentEvent.endDatetime) {
        const endDateTime = DateTime.fromISO(this.currentEvent.endDatetime);
        return endDateTime.toLocaleString({
          hour: 'numeric',
          minute: '2-digit',
          timeZoneName: 'short',
        });
      }
      return null;
    },
    times() {
      return timelineLabels('09:00', 15, 'minute');
    },
    timeLocalFormat() {
      return DateTime.DATE_FULL;
    },
    price: {
      // getter
      get() {
        return `$${this.eventObject.ticketPrice || ''}`;
      },
      // setter
      set(newValue) {
        let setValue = newValue;
        if (newValue.indexOf('$') !== -1) {
          setValue = newValue.slice(1, newValue.length);
        }
        if (setValue === '') {
          this.eventObject.ticketPrice = null;
        } else {
          this.eventObject.ticketPrice = setValue;
        }
      },
    },
  },
};
</script>

<style lang="scss">
.attending_btn {
  @apply flex justify-center items-center rounded border w-full min-w-[40px] px-3 py-2 border-solid border-gray-400 text-gray-900 text-center text-sm font-semibold hover:border-orange-400;
}

.attending_btn__active {
  @apply bg-orange-400 text-white border-orange-400;
}

.chevron-icon.down {
  transform: rotate(180deg);
}

#quill-container.ql-container.ql-snow {
  @apply border-none font-sans;
}

.quillWrapper .ql-toolbar.ql-snow {
  @apply border-none;
}

.disabled-text-editor #quill-container .ql-editor {
  @apply p-0;
  min-height: auto;
}

.ql-editor {
  overflow-y: hidden !important;
}

.textWrapperShadow {
  position: relative;
  pointer-events: all;

  &::after {
    pointer-events: none;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(0deg, #ffffff 0%, #ffffff 10%, rgba(255, 255, 255, 0) 40%);
  }
}

.event-information-block {
  @apply flex p-6 sm:pt-6 sm:px-0 sm:pb-0 justify-start sm:justify-center rounded border border-gray-300 w-full h-auto min-h-[124px] sm:w-257px;
}

.attendees-btn {
  padding: 0 22px !important;
}

.event-information-icon circle,
.event-information-icon path {
  stroke: #2a2b2e;
}

.publish-btn:not(:hover) {
  @apply border-primary bg-primary-transparent;

  &.default-color {
    @apply border-defaultInteractive bg-defaultInteractive-transparent;
  }
}

.saved-icon path {
  stroke: #008755;
}

.loading-icon {
  circle {
    @apply stroke-primary;
  }
}

.quillWrapper.admin-view-editor {
  border: 1px solid #dfdfe0 !important;

  .ql-toolbar.ql-snow {
    border-bottom: 1px solid #dfdfe0 !important;
  }
}

.input-container {
  @apply flex items-center mb-6 max-w-full md:max-w-[75%];

  &:last-child {
    @apply mb-0 sm:mb-6;
  }

  .calendar input {
    @apply h-11 border border-gray-300;
  }
}

.symbol-prefix {
  margin-top: 1px;
}

.event-type-radio + label {
  @apply flex justify-between items-center h-9 cursor-pointer block;

  span {
    @apply w-full text-sm text-center;
  }
}

.event-type-radio:disabled:checked + label {
  @apply bg-gray-100 opacity-90;
}

.event-type-radio:checked + label {
  @apply bg-white border border-gray-200 shadow-drop rounded;
}

.publish-link:hover {
  .alert-icon path,
  .alert-icon circle {
    @apply stroke-primary;
  }
}

.edit-btn:hover {
  &.default-color path {
    @apply stroke-defaultInteractive;
  }

  path {
    @apply stroke-primary;
  }
}

.maps-event-input {
  .input {
    font-size: 14px;
    padding: 10px 16px;
  }
}

.alert-icon {
  @apply w-[18px] ml-[8px];

  circle,
  path {
    @apply stroke-green;
  }
}

@media all and (max-width: 576px) {
  .quillWrapper .ql-toolbar.ql-snow .ql-formats {
    margin-right: 10px;
  }
}
</style>
