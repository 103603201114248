<template>
  <div class="block no-print">

    <!--                         -->
    <!-- SHORT TRIGGER CONTAINER -->
    <!--                         -->

    <label :class="{'bg-opacity-30': !!existingImage}"
           @click="showManagePictureDialog = true"
           v-if="shortVersion"
           class="cursor-pointer focus:outline-none offset-none flex p-3 lg:py-3 lg:px-6
                    animation-focus-default rounded bg-black bg-opacity-10">
      <ImageIcon class="w-4 h-4 lg:mr-3 -mt-1 lg:mt-0"></ImageIcon>
      <span class="hidden lg:flex text-white text-sm font-medium w-max">Upload a Cover</span>
    </label>

    <!--                        -->
    <!-- FULL TRIGGER CONTAINER -->
    <!--                        -->

    <div v-else-if="!shortVersion"
         class="border rounded"
         :class="{
          'flex justify-center': centerContainer,
          'border-red-600': invalid,
          'border-transparent': !invalid,
         }"
    >
      <div class="flex flex-col sm:flex-row">
        <div class="inline-flex flex-col items-center
                     justify-center p-1 border-4 border-dashed
                     border-transparent rounded bg-gray-200 shadow-md
                     transition-color duration-200 ease-out h-240px sm:w-600px"
        >
          <label class="w-full" @click="openManagePictureDialog()" :class="{'cursor-pointer': !isCropped}">
            <div :class="{'animation-focus-default': !isCropped, 'w-full': isCropped}"
                 class="flex justify-between items-center rounded"
                 id="cover-container"
            >
              <div v-if="!isCropped"
                   class="empty-state-container flex items-center justify-center
                             rounded mx-auto max-h-293px w-full h-240px sm:w-212px sm:h-212px sm:max-w-212px
                             lg:w-293px lg:h-293px lg:max-w-293px">
                <div class="animation-focus-default w-100px h-100px
                             lg:w-130px lg:h-130px flex items-center
                             justify-center rounded-full bg-white shadow">
                  <UploadImageIcon class="upload-icon w-12 h-12 lg:w-14 lg:h-14 stroke-gray-800"></UploadImageIcon>
                </div>
              </div>
              <div v-else class="relative w-full h-full">
                <img :src="croppedPreview"
                     class="rounded w-full h-full"
                     alt="cropped-image"
                />

                <div class="absolute z-10 top-2 right-2 rounded-full bg-gray-800
                 cursor-pointer animation-focus-default p-1">
                  <CloseIcon @click.stop.prevent="removeCroppedPreview()"
                             v-if="isCropped"
                             class="close-icon w-4 h-4"
                  ></CloseIcon>
                </div>
              </div>
            </div>
            <div v-if="showUnderPhotoText" class="flex items-center justify-center text-center">
              <h2 class="font-sans font-bold text-lg text-primary my-3">
                <template v-if="!isCropped">
                  Upload group Photo
                </template>
                <template v-else-if="isCropped && existingImage">
                  Change Photo
                </template>
                <template v-else>
                  Choose a New Photo
                </template>
              </h2>
            </div>
          </label>
        </div>

        <div class="flex flex-col" v-if="showSideText && !isCropped">
          <div class="flex flex-col sm:ml-8 mt-5 sm:mt-0 mb-5 items-center sm:items-start">

            <h2 class="font-bold text-lg"> Add a cover image for your group</h2>
            <p>This will display at the top of your group page. You can always add it later.</p>

          </div>
          <div class="sm:ml-8 flex justify-center sm:justify-start mb-5">
            <label @click="showManagePictureDialog = true"
                   class="inline-block font-bold leading-relaxed px-8 py-3 relative text-center
                          appearance-none rounded cursor-pointer transform
                          focus:outline-none
                          active:scale-95 hover:shadow-lg
                          transition duration-200 ease bg-gray-700 text-white text-sm"
            >
              + Upload an Image
            </label>
          </div>
          <div class="sm:ml-8 flex justify-center sm:justify-start text-sm">
            recommended dimensions: 1440 x 540 (or larger)
          </div>
          <div class="sm:ml-8 flex justify-center sm:justify-start text-sm">
            file size: png, jpg, gif up to 10MB
          </div>

        </div>
      </div>
    </div>

    <!--                        -->
    <!-- DIALOG TO UPLOAD COVER -->
    <!--                        -->
    <transition name="component-fade" mode="out-in">
      <ManagePictureDialog
          v-if="showManagePictureDialog"
          header="Upload a Cover"
          :cropped-preview-width="150"
          :boundary="{ width: boundary.width, height: boundary.height }"
          :view-port="{ width: viewPort.width, height: viewPort.height }"
          @cancel="hideManagePictureDialog()"
          @upload="uploadPicture($event)"
      ></ManagePictureDialog>
    </transition>
  </div>
</template>

<script>
import ManagePictureDialog from '@/components/ManagePictureDialog.vue';
import ImageIcon from '@/assets/icon_image_splash.svg';
import UploadImageIcon from '@/assets/icon_upload_image.svg';
import CloseIcon from '@/assets/icon_close.svg';

export default {
  name: 'GroupPhotoUpload',
  components: {
    ManagePictureDialog,
    UploadImageIcon,
    ImageIcon,
    CloseIcon,
  },
  props: {
    /**
     * Existing profile picture source
     */
    existingImage: {
      default: () => null,
    },
    /**
     * Error state for photo upload
     */
    invalid: {
      default: () => false,
      required: false,
      type: Boolean,
    },
    /**
     * Container should be flex
     */
    centerContainer: {
      default: () => false,
    },
    showSideText: {
      default: () => true,
    },
    showUnderPhotoText: {
      default: () => false,
    },
    shortVersion: {
      default: () => false,
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {
      croppedPreview: null,
      isCropped: false,
      showManagePictureDialog: false,
      boundary: {
        width: 352,
        height: 197,
      },
      viewPort: {
        width: 352,
        height: 133,
      },
      coverContainer: null,
    };
  },
  created() {
    if (this.existingImage) {
      this.croppedPreview = this.existingImage;
      this.isCropped = true;
    }

    if (document.body.clientWidth <= 576) {
      this.boundary.width = 230;
      this.boundary.height = 130;
      this.viewPort.width = 230;
      this.viewPort.height = 87;
    }

    this.calculateHeightOfCover();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    calculateHeightOfCover() {
      if (this.isCropped) {
        this.coverContainer = document.getElementById('cover-container');

        if (this.coverContainer) {
          const height = this.coverContainer.clientWidth / 2.26;
          this.coverContainer.setAttribute('style', `height: ${height}px`);
        }
      } else if (this.coverContainer) {
        this.coverContainer.setAttribute('style', 'height: auto');
      }
    },
    handleResize() {
      if (document.body.clientWidth <= 576) {
        this.boundary.width = 230;
        this.boundary.height = 130;
        this.viewPort.width = 230;
        this.viewPort.height = 87;
      } else {
        this.boundary.width = 352;
        this.boundary.height = 197;
        this.viewPort.width = 352;
        this.viewPort.height = 133;
      }

      this.calculateHeightOfCover();
    },
    openManagePictureDialog() {
      if (!this.isCropped) {
        this.showManagePictureDialog = true;
      }
    },
    hideManagePictureDialog() {
      this.showManagePictureDialog = false;
    },
    uploadPicture({
      cropped,
      preview,
    }) {
      this.isCropped = true;
      this.croppedPreview = preview;

      // set pause to apply another styles
      setTimeout(() => {
        this.calculateHeightOfCover();
        this.hideManagePictureDialog();
        this.$emit('submitted', cropped);
      });
    },
    removeCroppedPreview() {
      this.croppedPreview = null;
      this.isCropped = false;
      this.calculateHeightOfCover();
    },
  },
};
</script>

<style scoped lang="scss">
.close-icon path {
  @apply stroke-white;
}

.upload-icon path{
  @apply stroke-gray-800
}
</style>
