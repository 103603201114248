<template>
  <section
    ref="modal"
    class="flex items-center w-full h-full fixed inset-0 outline-none z-50"
    tabindex="0"
    @keydown.esc="close">

    <div @click="close" class="bg-black opacity-25 w-full h-full fixed z-50"></div>

    <div
      class="modal bg-gray-100 my-0 sm:my-16 absolute px-10 pb-10 flex flex-col
      rounded z-50 max-w-100vw w-full sm:max-w-4xl md:w-185 mt-20"
      :class="{
        'pt-6': !messageSent,
        'pt-10': messageSent,
      }">

      <CloseIcon
        v-if="!messageSent"
        @click="close"
        class="close-icon cursor-pointer animation-focus-default w-5 h-5 absolute top-3 lg:top-4 right-3 lg:right-4"
      ></CloseIcon>

      <template v-if="!messageSent">
        <h1 class="font-serif text-3xl font-bold text-gray-900">Message your group</h1>
        <p class="text-gray-600 py-4 font-sans text-lg mb-4 sm:mb-0">
          An email will be sent to all members of the group who have opted-in to receiving messages.
        </p>
        <TextArea
          id="emails"
          placeholder="Your group message"
          @input="updateMessage"
          :autocapitalize="false"
        />
        <div class="pt-4 flex self-end">
          <Button
            type="primary"
            @onClick="handleSendMessage"
            :disabled="buttonIsDisabled"
            :loading="isLoading"
          >
            Send Message
          </Button>
        </div>
        <template v-if="errorMessage">
          <ErrorBox :message="errorMessage" />
        </template>
      </template>
      <template v-else-if="messageSent">
        <div class="flex items-center">
          <SuccessIcon />
          <h1 class="font-serif text-3xl font-bold text-gray-900 pl-3">Message sent!</h1>
        </div>
        <p class="text-gray-600 py-4 font-sans text-lg">
          An email with your message has been sent to members of this group!
        </p>
        <div class="self-end">
          <Button
            type="primary"
            @onClick="close"
          >
            Close
          </Button>
        </div>
      </template>
    </div>
  </section>
</template>
<script>
import TextArea from '@/components/TextArea.vue';
import Button from '@/components/Button.vue';
import ErrorBox from '@/components/ErrorBox.vue';
import { mapActions, mapGetters } from 'vuex';
import SuccessIcon from '@/assets/success_icon.svg';
import CloseIcon from '@/assets/icon_close.svg';

export default {
  name: 'GroupInviteModal',
  components: {
    TextArea,
    Button,
    ErrorBox,
    SuccessIcon,
    CloseIcon,
  },
  data() {
    return {
      messageSent: false,
      message: '',
      errorMessage: '',
    };
  },
  props: {
    /**
     * Hide modal to exit
     */
    hideModal: {
      type: Function,
    },
  },
  mounted() {
    // used to allow closing with esc button
    if (this.$refs.modal) {
      this.$refs.modal.focus();
    }
  },
  computed: {
    ...mapGetters(['isLoading']),
    buttonIsDisabled() {
      return this.isLoading || !this.message;
    },
  },
  methods: {
    ...mapActions(['sendGroupMessage']),
    updateMessage(message) {
      this.message = message;
    },
    handleSendMessage() {
      this.sendGroupMessage({
        groupSlug: this.$route.params.group,
        message: this.message,
      }).then((response) => this.handleSendMessageResponse(response));
    },
    handleSendMessageResponse(response) {
      if (response.success) {
        this.errorMessage = '';
        this.messageSent = true;
      } else {
        this.errorMessage = 'Your message could not be sent. Please try again.';
      }
    },
    close() {
      this.hideModal();
    },
  },
};
</script>
<style scoped>
  .link {
    overflow-wrap: break-word;
  }
  .modal {
    left: 50%;
    transform: translateX(-50%);
  }
</style>
