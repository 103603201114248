<template>
  <div class="w-full flex my-2 pl-4 pr-2 sm:pr-1 py-3 font-semibold flex-col bg-gray-100 rounded">
    <div class="w-full flex justify-between items-center">
      <div class="flex w-full">
        <div class="flex flex-col flex-grow">
          <div class="flex items-center mb-2">
            <div v-if="profileField.icon" class="mr-2">{{ profileField.icon }}</div>
            <div class="flex text-gray-900 text-sm font-semibold leading-[140%] mr-3">
              {{ profileField.question }}
            </div>
            <div
              class="flex justify-center items-center rounded px-2 py-1 text-green-100 text-xs font-bold uppercase bg-green-100/10"
            >
              {{ profileField.label }}
            </div>
          </div>
          <div class="flex flex-col sm:flex-row mt-2 items-start">
            <div
              v-if="profileField.showOnProfile"
              class="flex items-center font-semibold text-gray-600 text-sm mb-2 sm:mb-0 mr-0 sm:mr-4 gap-2 px-4 py-2 rounded-[40px] bg-white"
            >
              <Check class="mr-1 scale-75" />
              <div>Visible On Profiles</div>
            </div>
            <div
              v-if="profileField.showOnYearbook"
              class="flex w-auto items-center font-semibold text-gray-600 gap-2 px-4 py-2 rounded-[40px] bg-white text-sm"
            >
              <Check class="mr-1 scale-75" />
              <div>Visible On Members</div>
            </div>
          </div>
        </div>
        <button type="button" class="action-btn" @click="showEditModal">
          <Edit />
        </button>
        <button type="button" class="action-btn" @click="showDeleteModal">
          <Delete />
        </button>
      </div>
    </div>

    <transition-group name="component-fade" mode="out-in">
      <DialogDefault
        v-if="isShowDeleteFieldModal"
        header="Delete Prompt"
        @cancel="hideDeleteModal"
        @apply="deleteField"
        is-show-close-btn
        scheme="secondary"
        key="DeleteModal"
        apply-btn-text="Delete"
      >
        <p class="text-gray-900 text-base font-normal leading-[160%]">
          Are you sure you want to delete this video prompt?
        </p>
        <p
          class="rounded px-3 py-4 bg-gray-100 text-gray-900 text-sm font-semibold leading-[140%] mt-4 mb-8"
        >
          "{{ profileField.question }}"
        </p>
      </DialogDefault>

      <DialogDefault
        v-if="isShowEditModal"
        header="Edit Profile Question"
        @cancel="hideEditModal"
        is-show-close-btn
        :is-show-control-buttons="false"
        scheme="secondary"
        container-class="sm:w-[600px]"
        key="EditModal"
      >
        <FormAddEditField @submit="saveField" @cancel="hideEditModal" :field="profileField" />
      </DialogDefault>
    </transition-group>

    <Toast v-if="errorMessage">{{ errorMessage }}</Toast>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Check from '@/assets/check.svg';
import Edit from '@/assets/edit.svg';
import Delete from '@/assets/delete.svg';
import DialogDefault from '@/components/DialogDefault.vue';
import FormAddEditField from '@/components/forms/FormAddEditField.vue';
import Toast from '@/components/Toast.vue';

export default {
  name: 'CustomFieldItem',
  components: {
    Toast,
    FormAddEditField,
    DialogDefault,
    Check,
    Edit,
    Delete,
  },
  computed: {
    ...mapGetters(['group']),
  },
  props: {
    profileField: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      errorMessage: '',
      isShowEditModal: false,
      isShowDeleteFieldModal: false,
    };
  },
  methods: {
    ...mapActions(['deleteProfileField', 'fetchProfileFields', 'updateProfileField']),

    showDeleteModal() {
      this.isShowDeleteFieldModal = true;
    },
    hideDeleteModal() {
      this.isShowDeleteFieldModal = false;
    },
    showEditModal() {
      this.isShowEditModal = true;
    },
    hideEditModal() {
      this.isShowEditModal = false;
    },
    async deleteField() {
      try {
        await this.deleteProfileField({
          groupSlug: this.$route.params.group,
          customFieldSlug: this.profileField.slug,
        });
        await this.fetchProfileFields({ groupSlug: this.$route.params.group });
      } catch (error) {
        console.log(error);
        this.errorMessage = 'Your custom field could not be deleted.';
      }
    },
    async saveField(updatedField) {
      try {
        await this.updateProfileField({
          groupSlug: this.$route.params.group,
          customFieldSlug: this.profileField.slug,
          body: updatedField,
        });
        this.hideEditModal();
        await this.fetchProfileFields({ groupSlug: this.$route.params.group });
      } catch (error) {
        console.log(error);
        this.errorMessage = 'Your edits failed to save.';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.action-btn {
  @apply flex items-center justify-center hover:bg-white w-[30px] md:w-[40px] h-[30px] md:h-[40px] p-0.5 md:p-2 rounded-sm;
  &:hover {
    path,
    circle,
    ellipse {
      @apply stroke-gray-800;
    }
  }
}
</style>
