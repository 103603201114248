import { render, staticRenderFns } from "./AdminEmailModal.vue?vue&type=template&id=278f7c66&scoped=true&"
import script from "./AdminEmailModal.vue?vue&type=script&lang=js&"
export * from "./AdminEmailModal.vue?vue&type=script&lang=js&"
import style0 from "./AdminEmailModal.vue?vue&type=style&index=0&id=278f7c66&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "278f7c66",
  null
  
)

export default component.exports