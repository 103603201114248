<template>
  <div class="flex items-center flex-row" :class="{ 'justify-between': isAttending }">
    <div
      v-if="showViewAllProfilesLink && !isAttending"
      @click="profileClick"
      class="flex items-center flex-shrink-0 sm:mb-0 font-semibold mr-4 sm:mr-5 animation-focus-default"
    >
      <ArrowLeft class="icon-arrow-right rotate-180 text-gray-800 w-4 mr-1" />
      <div key="members" class="inline-block text-sm text-sans appearance-none cursor-pointer mx-2">
        <span class="text-gray-800 hidden sm:block">View all members</span>
        <span class="text-gray-800 block sm:hidden">All profiles</span>
      </div>
    </div>

    <div class="flex flex-row">
      <template v-if="profiles.length">
        <a
          :key="profile.id"
          v-for="(profile, index) in filteredProfiles"
          @click="openGroupProfile(profile)"
          class="sm:w-12 flex-shrink-0 cursor-pointer animation-focus-default"
          @mouseover="indexProfileHovered = index"
          @mouseleave="indexProfileHovered = null"
          :class="[{ '-ml-3': index > 0, 'w-10': showViewAllProfilesLink && !isAttending }]"
        >
          <div
            :class="[
              { opaque: currentProfile(profile) },
              { 'my-4': useMargins },
              { 'color-default': isColorDefault() },
            ]"
            :style="
              customBorderColor && indexProfileHovered === index
                ? { 'border-color': getBorderColor() }
                : {}
            "
            class="photo-wrapper"
          >
            <ProfilePhoto :url="profile.profilePicture" :alt="getThumbnailAltText(profile)" />
            <div
              @click.stop="profileClick"
              v-if="profiles.length > currentProfilesCount && index === currentProfilesCount - 1"
              class="splash"
            >
              <div class="label">+{{ totalMemberCount - currentProfilesCount }}</div>
            </div>
          </div>
        </a>
      </template>
      <template v-else>
        <div
          v-for="n in currentProfilesCount"
          class="w-17 h-17 flex-shrink-0"
          :key="`profile-image-loader-${n}`"
        >
          <div class="relative w-14 h-14 rounded my-4 flex-shrink-0 overflow-hidden">
            <ProfilePhoto class="opacity-0" url="" />
          </div>
        </div>
      </template>
    </div>
    <div v-if="isAttending" class="flex items-center font-semibold animation-focus-default sm:ml-6">
      <a
        key="members"
        @click="showAll"
        class="inline-block text-sm text-gray-800 text-sans appearance-none cursor-pointer"
      >
        {{ profiles.length }} Attending
      </a>
      <ArrowLeft class="sm:hidden attending-arrow-icon rotate-180 text-gray-800 ml-2"></ArrowLeft>
    </div>
  </div>
</template>

<script>
import ArrowLeft from '@/assets/icon_arrow_right.svg';
import ProfilePhoto from '@/components/ProfilePhoto.vue';
import { DEFAULT_COLOR } from '@/components/constants/groupColors';

export default {
  name: 'ProfilesBanner',
  components: {
    ArrowLeft,
    ProfilePhoto,
  },
  props: {
    /**
     * Profile array to display
     */
    profiles: {
      default: () => [],
    },
    totalMemberCount: {
      type: Number,
      default: 0,
    },
    /**
     * Provide a group slug manually, if the banner is out of some group
     */
    currentGroupSlug: {
      type: String,
      default: null,
    },
    /**
     * Another mode to display a shortlist of profiles
     */
    isAttending: {
      default: () => false,
    },
    maxProfilesCount: {
      default: () => 8,
    },
    maxProfilesCountMobile: {
      default: () => 5,
    },
    /**
     * Determines whether the "View all profiles" link is shown
     */
    showViewAllProfilesLink: {
      default: true,
    },
    useMargins: {
      default: true,
    },
    customBorderColor: {
      type: String,
      default: null,
    },
    /**
     * Determines whether they should link to public profiles
     */
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentProfilesCount: 0,
      indexProfileHovered: null,
      defaultColor: DEFAULT_COLOR,
    };
  },
  computed: {
    filteredProfiles() {
      if (this.profiles.length > this.currentProfilesCount) {
        return this.profiles
          .filter((p) => {
            return p.status !== 'suspended';
          })
          .slice(0, this.currentProfilesCount);
      }

      return this.profiles.filter((p) => p.status !== 'suspended');
    },
  },
  created() {
    this.currentProfilesCount =
      document.body.clientWidth <= 576 ? this.maxProfilesCountMobile : this.maxProfilesCount;
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    isColorDefault() {
      return this.group ? this.group.primaryColor === this.defaultColor : true;
    },
    getBorderColor() {
      return `rgb(${this.customBorderColor})`;
    },
    openGroupProfile(user) {
      // make user as object only to use full path to the profile page
      if (user.slug) {
        const path = `/${this.isPublic ? 'public' : 'group'}/${
          this.$route.params.group || this.currentGroupSlug
        }/profile/${user.slug}`;

        if (this.$route.path !== path) {
          this.$router.replace(path);
        }
      } else {
        this.$router.replace(
          `//${this.isPublic ? 'public' : 'group'}/${
            this.$route.params.group || this.currentGroupSlug
          }`,
        );
      }
    },
    getThumbnailAltText(profile) {
      if (profile.firstName && profile.lastName) {
        return `View ${profile.firstName} ${profile.lastName}`;
      }
      return '';
    },
    currentProfile(profile) {
      const userSlug = this.$route.params.user;
      return profile.slug === userSlug;
    },
    showAll() {
      this.$emit('showAll');
    },
    handleResize() {
      this.currentProfilesCount =
        document.body.clientWidth <= 576 ? this.maxProfilesCountMobile : this.maxProfilesCount;
    },
    profileClick() {
      if (this.isPublic) {
        this.$router.push({ name: 'public-members' });
      } else {
        this.$router.push({ name: 'members' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-arrow-right {
  transform: rotate(180deg);

  path {
    @apply fill-gray-800;
  }
}

.opaque {
  @apply opacity-100;
}

.loader {
  width: 100%;
  min-width: 0;
  max-width: 300px;
}

.photo-wrapper {
  @apply relative w-13 border-2 border-transparent hover:border-primary rounded text-xs flex-shrink-0 overflow-hidden rounded-full;

  &.color-default {
    @apply hover:border-defaultInteractive;
  }

  img {
    @apply rounded rounded-full border-3 border-white;
  }
}

.splash {
  @apply w-11 h-11 sm:w-13 sm:h-13 border-3 border-white rounded-full absolute flex justify-center items-center;
  top: -3px;
  right: -3px;

  @media all and (max-width: 576px) {
    top: 2px;
    right: 1px;
  }

  &::before {
    content: '';
    background-color: rgb(46, 46, 46);
    opacity: 0.6;
    @apply w-11 h-11 sm:w-13 sm:h-13 absolute z-0 rounded-full;
  }

  .label {
    letter-spacing: 0.25px;
    @apply text-grayscale text-white text-sm font-normal z-10;
  }
}

.attending-arrow-icon {
  width: 14px;
}
</style>
