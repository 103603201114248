<template>
  <div v-cloak class="group-page relative" v-if="!isLoading">
    <div
      class="top-controls absolute flex w-full pt-4 lg:pt-6 justify-between items-center z-50 2xl:w-2xl px-6 lg:px-30 3xl:px-20"
    >
      <div
        class="flex w-full justify-end lg:justify-between items-center no-print"
        v-if="!notAuthenticatedOrNotMember"
      >
        <a
          v-if="$route.name === 'event'"
          @click="backToEvents()"
          class="hidden cursor-pointer lg:flex items-center text-white text-sm animation-focus-default"
        >
          <BackspaceIcon class="w-5 mr-3"></BackspaceIcon>
          <span class="text-white">Back to all events</span>
        </a>
        <a
          v-else
          @click="backToGroups()"
          class="hidden cursor-pointer lg:flex items-center text-white text-sm animation-focus-default"
        >
          <BackspaceIcon class="w-5 mr-3"></BackspaceIcon>
          <span class="text-white">Back to all groups</span>
        </a>
      </div>

      <GroupPhotoUpload
        v-if="isAdmin"
        @submitted="handlePhotoUpload"
        :shortVersion="true"
        :showSideText="false"
        :showUnderPhotoText="true"
        :existingImage="$route.name === 'event' ? photoUrl : group.groupPhoto"
      />
    </div>

    <div
      class="banner relative overflow-hidden no-print"
      v-if="!notAuthenticatedOrNotMember"
      :style="{
        'background-image': `url('${
          $route.name === 'event' ? photoUrl : group.groupPhoto ? group.groupPhoto : null
        }')`,
      }"
      :class="{
        'opacity-transition': isTransitionForEventPage,
        'h-60 lg:h-540px bg-transparent': $route.name === 'event' ? photoUrl : group.groupPhoto,
        'bg-primary h-180px lg:h-80': $route.name === 'event' ? !photoUrl : !group.groupPhoto,
      }"
    >
      <div
        class="relative banner-content 2xl:w-2xl 2xl:mx-auto h-full flex flex-col justify-end p-6 sm:pl-12 sm:py-4 sm:pr-6 lg:py-8 lg:px-30 3xl:px-20"
      >
        <div class="block z-10">
          <Breadcrumbs
            class="mb-3 w-30 lg:w-auto overflow-hidden"
            :is-white="true"
            :crumbs="breadcrumbs"
          />

          <h1 v-if="$route.name === 'event'" class="text-white truncate">
            {{ currentEvent.name }}
          </h1>
          <template v-else>
            <h1 class="text-white truncate">{{ group.name }}</h1>
            <h4 v-if="group.subtitle" class="text-white truncate subtitle">
              {{ group.subtitle }}
            </h4>
          </template>

          <Badge
            v-if="$route.name === 'event' && eventDateFormatted"
            :is-white="true"
            :is-transparent="!photoUrl"
            class="group-card-badge text-white mt-3 sm:mb-2 lg:mb-4"
            :text="eventDateFormatted"
            :background="group.primaryColor"
          />
          <template v-else>
            <div class="flex items-center flex-wrap">
              <Badge
                :is-white="true"
                :is-transparent="!group.groupPhoto"
                class="group-card-badge text-white mt-3 sm:mb-2 lg:mb-4 mr-2"
                :icon="group.isSubscribed ? 'diamond' : 'people'"
                :text="(group.isSubscribed ? 'Exclusive' : 'Public') + ' Group'"
                :background="group.primaryColor"
              />
              <Badge
                v-if="group.groupClassification && group.groupClassification.name"
                :is-white="true"
                :is-transparent="!group.groupPhoto"
                class="group-card-badge text-white mt-3 sm:mb-2 lg:mb-4"
                :icon="group.groupClassification.name.toLowerCase()"
                :text="group.groupClassification.name"
                :background="group.primaryColor"
              />
            </div>
          </template>
        </div>
      </div>

      <img
        v-if="$route.name === 'event' && !isTransitionForEventPage"
        :src="photoUrl"
        id="eventBanner"
        class="banner-photo"
        alt="banner_photo"
      />
      <img
        v-else-if="group.groupPhoto && !isTransitionForEventPage"
        :src="group.groupPhoto"
        id="groupBanner"
        class="banner-photo"
        alt="banner_photo"
      />

      <div class="banner-contrast" />
      <div class="banner-blur" />
    </div>

    <div class="tabs-wrapper relative sm:px-12 lg:px-30 pb-4 lg:pb-32 mt-0 lg:mt-4">
      <div class="relative px-6 pt-6 sm:p-0 mx-auto my-auto" v-if="!notAuthenticatedOrNotMember">
        <DropdownTabsMobile class="mb-6 no-print" :current-value="currentPage">
          <ul>
            <li class="mb-1" @click="currentPage = 'Members'">
              <router-link key="members" :to="{ name: 'members' }" class="link">
                <div class="dropdown-tab-item">Members</div>
              </router-link>
            </li>
            <li class="mb-1" @click="currentPage = 'Group Info'">
              <router-link key="description" :to="{ name: 'description' }" class="link">
                <div class="dropdown-tab-item">Group Info</div>
              </router-link>
            </li>
            <li class="mb-1" @click="currentPage = 'Events'">
              <router-link key="events" :to="{ name: 'events' }" class="link">
                <div class="dropdown-tab-item">Events</div>
              </router-link>
            </li>
            <li v-if="isPhotoTabShow" class="mb-1" @click="currentPage = 'Photos'">
              <router-link key="events" :to="{ name: 'photos' }" class="link">
                <div class="dropdown-tab-item">Photos</div>
              </router-link>
            </li>
            <li v-if="isMessageFeedTabShow" class="mb-1" @click="currentPage = 'Feed'">
              <router-link key="events" :to="{ name: 'feed' }" class="link">
                <div class="dropdown-tab-item">Message Board</div>
              </router-link>
            </li>
            <li v-if="isAdmin" class="" @click="currentPage = 'Admin'">
              <router-link key="events" :to="{ name: 'admin' }" class="link">
                <div class="dropdown-tab-item">Admin</div>
              </router-link>
            </li>
          </ul>
        </DropdownTabsMobile>
      </div>

      <div
        class="tabs-wrapper flex flex-col-reverse max-w-screen-xl mx-6 sm:mx-auto relative no-print"
        v-if="!notAuthenticatedOrNotMember"
      >
        <ul
          class="hidden sm:flex nav-items-container text-xl text-gray-600 border-b border-gray-400 text-base relative"
        >
          <li class="tab">
            <router-link
              key="members"
              :to="{ name: 'members' }"
              class="inline-block w-full hover:text-primary focus:text-primary focus:outline-none transition-colors duration-200 ease-out"
            >
              <h6
                :style="{
                  'border-bottom-color':
                    $route.name === 'members' || $route.name === 'profile'
                      ? `rgba(${group.primaryColor})`
                      : 'transparent',
                }"
                class="inline-block pb-5 border-b-3"
              >
                Members
              </h6>
            </router-link>
          </li>
          <li class="tab">
            <router-link
              key="description"
              :to="{ name: 'description' }"
              class="inline-block w-full hover:text-primary focus:text-primary focus:outline-none transition-colors duration-200 ease-out"
            >
              <h6
                :style="{
                  'border-bottom-color':
                    $route.name === 'description' ? `rgba(${group.primaryColor})` : 'transparent',
                }"
                class="inline-block pb-5 border-b-3"
              >
                Group Info
              </h6>
            </router-link>
          </li>
          <li class="tab">
            <router-link
              key="events"
              :to="{ name: 'events' }"
              class="inline-block w-full hover:text-primary focus:text-primary focus:outline-none transition-colors duration-200 ease-out"
            >
              <h6
                :style="{
                  'border-bottom-color':
                    $route.name === 'events' || $route.name === 'event'
                      ? `rgba(${group.primaryColor})`
                      : 'transparent',
                }"
                class="inline-block pb-5 border-b-3"
              >
                Events
              </h6>
            </router-link>
          </li>
          <li v-if="isPhotoTabShow" class="tab">
            <router-link
              key="events"
              :to="{ name: 'photos' }"
              class="inline-block w-full hover:text-primary focus:text-primary focus:outline-none transition-colors duration-200 ease-out"
            >
              <h6
                :style="{
                  'border-bottom-color':
                    $route.name === 'photos' ? `rgba(${group.primaryColor})` : 'transparent',
                }"
                class="inline-block pb-5 border-b-3"
              >
                Photos
              </h6>
            </router-link>
          </li>

          <li v-if="isMessageFeedTabShow" class="tab">
            <router-link
              key="events"
              :to="{ name: 'feed' }"
              class="inline-block w-full hover:text-primary focus:text-primary focus:outline-none transition-colors duration-200 ease-out"
            >
              <h6
                :style="{
                  'border-bottom-color':
                    $route.name === 'feed' ? `rgba(${group.primaryColor})` : 'transparent',
                }"
                class="inline-block pb-5 border-b-3"
              >
                Message Board
              </h6>
            </router-link>
          </li>
          <li v-if="isAdmin" class="tab">
            <router-link
              key="events"
              :to="{ name: 'admin' }"
              class="inline-block w-full hover:text-primary focus:text-primary focus:outline-none transition-colors duration-200 ease-out"
            >
              <h6
                :style="{
                  'border-bottom-color':
                    $route.name === 'admin' ? `rgba(${group.primaryColor})` : 'transparent',
                }"
                class="inline-block pb-5 border-b-3"
              >
                Admin
              </h6>
            </router-link>
          </li>
        </ul>

        <div
          class="pb-0 2xl:pb-8 2xl:absolute right-0 top-2 lg:top-0 flex justify-end gap-2 md:gap-4 2xl:justify-end no-print"
        >
          <Button
            v-if="isAdmin"
            :primary-full-size="false"
            type="secondary"
            @onClick="redirectToCreateMember"
            custom-class="h-[40px] w-[190px]"
            :disabled="isGroupFreeAndOverLimit"
            class="group create-member-btn"
          >
            <template v-slot:icon>
              <IconCircle class="w-[16px] group-icon" />
            </template>
            <span
              class="text-orange-400 text-center text-sm font-semibold leading-[140%] group-disabled:text-white"
            >
              Create Member
            </span>
          </Button>
          <Button
            class="w-[160px] h-[40px]"
            type="primary"
            compact
            :primaryFullSize="false"
            :disabled="isGroupFreeAndOverLimit"
            @onClick="showModal"
          >
            <template v-slot:icon>
              <AddUserIcon class="w-4" />
            </template>
            Invite Members
          </Button>

          <template v-if="isAIIntegrationEnabled">
            <button type="button" class="open-ai-btn" @click.stop="showAIForm">
              <IconStars class="w-[24px] h-[24px]" />
              <span class="hidden md:inline text-white pl-1 font-semibold"> Fun Insights </span>
            </button>
            <OpenAIForm v-if="isAIFormShow" @close="hideAIForm" />
          </template>
        </div>
      </div>

      <div class="child-pages-wrapper max-w-screen-xl sm:mx-auto">
        <router-view></router-view>
      </div>
    </div>

    <template v-if="inviteModalOpen">
      <transition name="component-fade" mode="out-in">
        <GroupInviteModal :hideModal="hideInviteModal" />
      </transition>
    </template>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Badge from '@/components/Badge.vue';
import BackspaceIcon from '@/assets/icon_backspace.svg';
import AddUserIcon from '@/assets/icon_add_user_white.svg';
import GroupPhotoUpload from '@/components/groups/GroupPhotoUpload.vue';
import DropdownTabsMobile from '@/components/DropdownTabsMobile.vue';
import { DateTime } from 'luxon';
import GroupInviteModal from '@/components/GroupInviteModal.vue';
import { DEFAULT_COLOR, DEFAULT_INTERACTIVE_COLOR } from '@/components/constants/groupColors';
import IconCircle from '@/assets/icon_plus_circled.svg';
import Button from '@/components/Button.vue';
import OpenAIForm from '@/views/Group/components/OpenAIForm.vue';
import IconStars from '@/assets/icon_stars.svg';

const MEMBERS_LIMIT = 20;

export default {
  name: 'Index',
  components: {
    IconStars,
    OpenAIForm,
    Button,
    IconCircle,
    DropdownTabsMobile,
    Badge,
    Breadcrumbs,
    BackspaceIcon,
    AddUserIcon,
    GroupPhotoUpload,
    GroupInviteModal,
  },
  data() {
    return {
      breadcrumbs: [
        {
          label: 'My Groups',
          link: 'groups',
        },
        {
          label: '',
          link: 'members',
        },
      ],
      isAIFormShow: false,
      showVideoPrompt: false,
      viewingPastEvents: false,
      profileInfo: {
        groupSlug: '',
        sort: '+last_name',
        filters: {},
      },
      currentPage: 'Members',
      isTransitionForEventPage: false,
      defaultColor: DEFAULT_COLOR,
      defaultButtonColor: DEFAULT_INTERACTIVE_COLOR,
      cbInstance: null,
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters([
      'user',
      'currentProfile',
      'isAuthenticated',
      'group',
      'profiles',
      'currentEvent',
      'inviteModalOpen',
      'groupFeatureFlags',
    ]),

    isAdmin() {
      return this.user.adminOfGroups && this.user.adminOfGroups.includes(this.$route.params.group);
    },

    isGroupFreeAndOverLimit() {
      return !this.group.isSubscribed && this.profiles.length >= MEMBERS_LIMIT;
    },

    isPhotoTabShow() {
      return (
        (this.group.isSubscribed &&
          this.groupFeatureFlags &&
          this.groupFeatureFlags.groupPhotosEnabled) ||
        !this.group.isSubscribed ||
        this.isAdmin
      );
    },
    isAIIntegrationEnabled() {
      return (
        (this.group.isSubscribed &&
          this.groupFeatureFlags &&
          this.groupFeatureFlags.aiIntegrationEnabled) ||
        !this.group.isSubscribed ||
        this.isAdmin
      );
    },
    isMessageFeedTabShow() {
      return (
        (this.group.isSubscribed &&
          this.groupFeatureFlags &&
          this.groupFeatureFlags.messageBoardEnabled) ||
        !this.group.isSubscribed ||
        this.isAdmin
      );
    },
    documentTitle() {
      return this.group.subtitle
        ? `${this.group.name} - ${this.group.subtitle}`
        : `${this.group.name}`;
    },
    photoUrl() {
      return this.currentEvent.photo;
    },
    eventDateFormatted() {
      if (this.currentEvent && this.currentEvent.startDatetime) {
        const startDateTime = DateTime.fromISO(this.currentEvent.startDatetime);
        return startDateTime.toLocaleString(DateTime.DATE_MED);
      }
      return '';
    },
    notAuthenticatedOrNotMember() {
      if (this.isSuperuser) return false;

      return (
        !this.isAuthenticated ||
        (this.isAuthenticated && !this.memberOfGroup()) ||
        this.$route.name === 'signup'
      );
    },
    isSuperuser() {
      return !!this.user && !!this.user.user && !!this.user.user.isSuperuser;
    },
  },
  beforeCreate() {
    this.isLoading = true;
  },

  async created() {
    const groupSlug = this.$route.params.group;
    if (groupSlug) {
      this.fetchGroup({ groupSlug }).then((data) => {
        if (data && !data.success) {
          this.$router.push({ name: 'not-found' });
        } else {
          this.breadcrumbs[1].label = this.group.name;
          this.isLoading = false;
        }
      });
    }

    await this.fetchFeatureFlags(groupSlug);

    this.setCurrentTab();
  },

  destroyed() {
    this.setGroupFeature(null);
  },

  methods: {
    ...mapMutations({
      setGroupFeature: 'SET_GROUP_FEATURE',
    }),
    ...mapActions([
      'fetchFeatureFlags',
      'fetchProfile',
      'fetchProfiles',
      'fetchGroup',
      'updateEventPhoto',
      'updateGroupPhoto',
      'uploadGroupFile',
      'updateModalOpen',
    ]),
    showAIForm() {
      this.isAIFormShow = true;
    },
    hideAIForm() {
      this.isAIFormShow = false;
    },
    isColorDefault() {
      return this.group.primaryColor === this.defaultColor;
    },
    getButtonDefaultColor() {
      return this.defaultButtonColor;
    },
    redirectToCreateMember() {
      this.$router.push({
        name: 'create-member',
        params: {
          slug: this.$route.params.group,
        },
      });
    },
    hideInviteModal() {
      this.updateModalOpen(false);
      document.body.style.overflow = 'auto';
      localStorage.setItem('invite-modal-opened', 'true');
    },
    handlePhotoUpload(blob) {
      if (this.$route.name === 'event') {
        this.updateEventPhoto({
          groupSlug: this.$route.params.group,
          eventId: this.$route.params.eventId,
          photoBlob: blob,
        }).then(() => {});
      } else {
        this.updateGroupPhoto({
          groupSlug: this.$route.params.group,
          photoBlob: blob,
        }).then(() => {});
      }
    },
    memberOfGroup() {
      return (
        this.user &&
        this.user.groups &&
        this.user.groups.some((group) => group.slug === this.$route.params.group)
      );
    },
    isSafariMobile() {
      const safariMobile = /iPad|iPhone|iPod/.test(navigator.platform);
      // we're mainly concerned about safari here but we'll check for ie too
      const ie = /MSIE|Trident/.test(navigator.userAgent);
      return !!navigator.platform && (safariMobile || ie);
    },
    safariDesktop() {
      return (
        !this.isSafariMobile &&
        navigator.userAgent.indexOf('Safari') !== -1 &&
        navigator.userAgent.indexOf('Chrome') === -1
      );
    },
    showModal() {
      // Google Analytics Event
      this.$gtag.event('clicked_invite', {
        event_category: 'members',
        event_label: `${this.$route.params.group} / Clicked invite button`,
      });
      this.updateModalOpen(true);
    },
    setCurrentTab() {
      switch (this.$route.name) {
        case 'members':
          this.currentPage = 'Members';
          break;
        case 'description':
          this.currentPage = 'Group Info';
          break;
        case 'events':
          this.currentPage = 'Events';
          break;
        case 'event':
          this.currentPage = 'Events';
          break;
        case 'admin':
          this.currentPage = 'Admin';
          break;
        default:
          this.currentPage = 'Members';
      }

      if (this.breadcrumbs[this.breadcrumbs.length - 1].link === 'event') {
        this.breadcrumbs.splice(this.breadcrumbs.length - 1, 1);
      }
    },
    backToEvents() {
      this.$router.push({ name: 'events' });
    },
    backToGroups() {
      this.$router.push({ name: 'groups' });
    },
  },
  watch: {
    currentEvent(newValue, oldValue) {
      if (this.currentPage === 'Events') {
        if (newValue.id !== oldValue.id || newValue.name !== oldValue.name) {
          const existingCrumbIndex = this.breadcrumbs.findIndex((crumb) => crumb.link === 'event');
          if (existingCrumbIndex !== -1) {
            this.breadcrumbs[existingCrumbIndex].label = newValue.name;
          } else {
            this.breadcrumbs.push({
              label: newValue.name,
              link: 'event',
            });
          }
        }
      }
    },
    $route(to, from) {
      document.title = this.documentTitle || to.meta.title;
      this.setCurrentTab();

      if (to.name === 'profile') {
        setTimeout(() => {
          document.querySelector('.child-pages-wrapper').scrollIntoView({
            behavior: 'smooth',
          });
        }, 500);
      }

      if (to.name === 'event' || from.name === 'event') {
        this.isTransitionForEventPage = true;

        setTimeout(() => {
          this.isTransitionForEventPage = false;
        }, 300);
      }
    },
    group() {
      document.title = this.documentTitle || this.$route().meta.title;
      this.profileInfo.groupSlug = this.group.slug;
      if (this.profileInfo.groupSlug) {
        if (this.user && this.user.slug && this.memberOfGroup) {
          this.fetchProfile({
            groupSlug: this.$route.params.group,
            profileSlug: this.user.slug,
          })
            .then((res) => {
              if (res.success) {
                this.showVideoPrompt =
                  !this.currentProfile.videos || !this.currentProfile.videos.length;
              }
            })
            .then(() => this.fetchProfiles(this.profileInfo));
        } else if (!this.group.isSubscribed) {
          this.fetchProfiles(this.profileInfo);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.banner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 1;
  transition: all 0.1s ease-in-out;

  &.opacity-transition {
    opacity: 0.3;
  }

  .banner-contrast {
    @apply absolute inset-0;
    z-index: 3;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
  }

  .banner-blur {
    @apply absolute inset-0;
    z-index: 1;
    backdrop-filter: blur(12px) brightness(1.2);
  }

  .banner-content {
    z-index: 4;
  }

  .banner-photo {
    @apply absolute top-1/2 left-1/2;
    transform: translate(-50%, -50%);
    width: 1440px;
    height: 540px;
    max-width: none;
    z-index: 2;

    @media all and (max-width: 576px) {
      height: 240px;
      width: calc(240px * 2.67);
    }
  }
}

.tab {
  @apply flex text-left text-center items-center mr-8;
}

.open-modal-btn {
  @apply w-155px sm:w-auto flex justify-center items-center py-3 sm:py-2 px-4 sm:px-6 text-sm font-medium rounded border;
}

.banner-btn {
  @apply w-155px sm:w-auto flex justify-center items-center py-3 sm:py-2 px-4 sm:px-6 font-medium text-base rounded border;
}

.router-link-active.link .dropdown-tab-item {
  @apply bg-gray-300;
}

.link .dropdown-tab-item {
  @apply p-3 hover:bg-gray-300 focus:bg-gray-300 rounded;
}

.top-controls {
  left: 50%;
  transform: translateX(-50%);
}

.camera-icon {
  path {
    stroke: #2a2b2e;
  }

  &.default {
    path {
      @apply stroke-orange;
    }
  }
}

.subtitle {
  font-family: 'Quando';
  font-weight: normal;
}

.v-cloak {
  display: none;
}

.open-ai-btn {
  @apply flex justify-center items-center min-w-[40px] md:w-[150px] h-[40px] rounded hover:shadow-purple;
  background: linear-gradient(90deg, #54b8f9 0%, #8a77f7 100%);
}
.create-member-btn:disabled .group-icon {
  path,
  circle {
    @apply stroke-white;
  }
}
</style>
