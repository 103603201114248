<template>
  <section
    ref="modal"
    class="flex items-center w-full h-full fixed inset-0 outline-none z-50"
    tabindex="0"
    @keydown.esc="close"
  >
    <div @click="close" class="bg-black opacity-25 w-full h-full fixed z-50"></div>

    <div
      class="modal bg-white absolute flex flex-col rounded-xl z-50 max-w-100vw sm:max-w-4xl my-3 sm:my-0 w-full md:w-600px md:h-600px top-app-header bottom-0 sm:top-auto sm:bottom-auto"
    >
      <CloseIcon
        @click="close"
        class="close-icon cursor-pointer animation-focus-default w-5 h-5 absolute top-3 lg:top-4 right-3 lg:right-4"
      ></CloseIcon>

      <div class="px-6 sm:px-12 pt-10 sm:pt-6 border-b border-gray-300">
        <DropdownTabsMobile class="mb-6" :current-value="dropdownValues[selectedList - 1]">
          <div
            class="link rounded"
            :class="{ 'bg-gray-100': selectedList === 1 }"
            @click="setList(1)"
          >
            <div class="dropdown-tab-item font-semibold">
              Attending
              <div class="text-sm font-normal text-gray-500 pt-1">
                {{ rsvpYesList.length }}
                {{ rsvpYesList.length === 1 ? 'person' : 'people' }}
              </div>
            </div>
          </div>
          <div
            class="link rounded"
            :class="{ 'bg-gray-100': selectedList === 2 }"
            @click="setList(2)"
          >
            <div class="dropdown-tab-item font-semibold">
              Not Attending
              <div class="text-sm font-normal text-gray-500 pt-1">
                {{ rsvpNoList.length }}
                {{ rsvpNoList.length === 1 ? 'person' : 'people' }}
              </div>
            </div>
          </div>
          <div
            class="link rounded"
            :class="{ 'bg-gray-100': selectedList === 3 }"
            @click="setList(3)"
          >
            <div class="dropdown-tab-item font-semibold">
              No Response
              <div class="text-sm font-normal text-gray-500 pt-1">
                {{ rsvpUnknownList.length }}
                {{ rsvpUnknownList.length === 1 ? 'person' : 'people' }}
              </div>
            </div>
          </div>
        </DropdownTabsMobile>

        <div
          class="hidden sm:flex bg-white border border-gray-300 rounded shadow-drop overflow-hidden"
        >
          <div
            :class="{ 'tab-active bg-gray-100 opacity-100 hover:opacity-100': selectedList === 1 }"
            class="relative attendees-tab flex-1 p-4 border-r border-gray-300 text-center animation-focus-default cursor-pointer opacity-50 hover:opacity-80 text-base font-semibold"
            @click="setList(1)"
          >
            <div class="text-base font-semibold mb-1">Attending</div>
            <div class="text-sm font-normal text-gray-500">
              {{ rsvpYesList.length }}
              {{ rsvpYesList.length === 1 ? 'person' : 'people' }}
            </div>
          </div>
          <div
            :class="{ 'tab-active bg-gray-100 opacity-100 hover:opacity-100': selectedList === 2 }"
            class="relative attendees-tab flex-1 p-4 text-center cursor-pointer animation-focus-default opacity-50 hover:opacity-80 text-base font-semibold"
            @click="setList(2)"
          >
            <div class="text-base font-semibold mb-1">Not Attending</div>
            <div class="text-sm font-normal text-gray-500">
              {{ rsvpNoList.length }}
              {{ rsvpNoList.length === 1 ? 'person' : 'people' }}
            </div>
          </div>
          <div
            :class="{ 'tab-active bg-gray-100 opacity-100 hover:opacity-100': selectedList === 3 }"
            class="relative attendees-tab flex-1 p-4 border-l border-gray-300 animation-focus-default text-center cursor-pointer opacity-50 hover:opacity-80"
            @click="setList(3)"
          >
            <div class="text-base font-semibold mb-1">No Response</div>
            <div class="text-sm font-normal text-gray-500">
              {{ rsvpUnknownList.length }}
              {{ rsvpUnknownList.length === 1 ? 'person' : 'people' }}
            </div>
          </div>
        </div>

        <div class="w-full block relative my-6">
          <TextInput
            id="newFieldQuestion"
            placeholder="Search"
            label="Search"
            v-model="searchTerm"
            :is-search="true"
            :search-include-my-list="true"
            :has-clear-icon="true"
          >
            <template v-slot:icon>
              <SearchIcon class="w-4"></SearchIcon>
            </template>
          </TextInput>

          <div class="checkbox-filter">
            <Checkbox
              id="filter_on_list2"
              label="On My List"
              primary-color
              v-model="filterOnList"
              @change="handleOnListFilter"
            ></Checkbox>
          </div>
        </div>
      </div>

      <div class="block pl-6 sm:pl-12 pr-3 pb-10 h-full overflow-hidden">
        <div class="overflow-auto h-full mt-5 pr-4 sm:pr-9 custom-scroll">
          <div
            v-for="(person, index) of rsvps"
            :key="person.id"
            :class="{ 'pt-5': index !== 0 }"
            class="flex justify-between"
          >
            <div class="flex">
              <div class="photo-wrapper w-11 text-xs flex-shrink-0 overflow-hidden rounded-full">
                <ProfilePhoto :url="person.profilePicture" />
              </div>
              <div
                class="my-auto ml-4 text-base font-semibold"
                v-html="getAttendeeName(person.firstName, person.lastName)"
              ></div>
            </div>
            <div v-if="isGroupAdmin" class="flex relative items-center">
              <!--              todo: fix menu dropdown when it located at the bottom of the list-->
              <MenuDropdownContainer>
                <div class="items-container p-2">
                  <input
                    id="going"
                    class="opacity-0 w-0 h-0 absolute"
                    v-model="person.rsvp"
                    type="radio"
                  />
                  <label
                    for="going"
                    class="cursor-pointer hover:bg-gray-100 text-gray-700 block px-4 py-2 mb-1 text-sm animation-focus-default"
                    :class="{ 'bg-gray-100 text-gray-900': +person.rsvp === 1 }"
                    role="menuitem"
                    tabindex="-1"
                    @click="chooseRsvp(person, 1)"
                  >
                    Going
                  </label>
                  <input
                    id="notGoing"
                    class="opacity-0 w-0 h-0 absolute"
                    v-model="person.rsvp"
                    type="radio"
                  />
                  <label
                    for="notGoing"
                    class="cursor-pointer hover:bg-gray-100 text-gray-700 block px-4 py-2 text-sm animation-focus-default"
                    :class="{ 'bg-gray-100 text-gray-900': +person.rsvp === 2 }"
                    role="menuitem"
                    tabindex="-1"
                    @click="chooseRsvp(person, 2)"
                  >
                    Not Going
                  </label>
                </div>
              </MenuDropdownContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ProfilePhoto from '@/components/ProfilePhoto.vue';
import TextInput from '@/components/TextInput.vue';
import MenuDropdownContainer from '@/components/MenuDropdownContainer.vue';
import SearchIcon from '@/assets/icon_search.svg';
import CloseIcon from '@/assets/icon_close.svg';
import Checkbox from '@/components/Checkbox.vue';
import DropdownTabsMobile from '@/components/DropdownTabsMobile.vue';
import { mapActions } from 'vuex';
import { filterRsvps } from '@/helpers';

export default {
  name: 'AttendeesModal',
  components: {
    ProfilePhoto,
    TextInput,
    MenuDropdownContainer,
    SearchIcon,
    CloseIcon,
    Checkbox,
    DropdownTabsMobile,
  },
  props: {
    rsvpYesList: {
      type: Array,
      default: () => [],
    },
    rsvpNoList: {
      type: Array,
      default: () => [],
    },
    rsvpUnknownList: {
      type: Array,
      default: () => [],
    },
    hideModal: {
      type: Function,
    },
    isGroupAdmin: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      selectedList: 1,
      searchTerm: '',
      filterOnList: false,
      eventData: {
        groupSlug: this.$route.params.group,
        eventId: this.$route.params.eventId,
      },
      dropdownValues: ['Attending', 'Not Attending', 'No Response'],
    };
  },
  mounted() {
    // used to allow closing with esc button
    if (this.$refs.modal) {
      this.$refs.modal.focus();
    }
  },
  methods: {
    ...mapActions(['postRsvp', 'fetchGroupEventAttendees']),
    close() {
      this.hideModal();
    },
    setList(list) {
      this.selectedList = list;
    },
    getAttendeeName(firstname, lastname) {
      const fullName = `${firstname} ${lastname}`;

      if (!this.searchTerm.length) {
        return fullName;
      }
      const highlighted = fullName.replace(new RegExp(this.searchTerm, 'gi'), (match) => {
        return `<span class="text-gray-900">${match}</span>`;
      });

      return `<span class="text-gray-500">${highlighted}</span>`;
    },
    chooseRsvp(person, rsvp) {
      // todo: update lists for correct displaying
      this.postRsvp({
        groupSlug: this.$route.params.group,
        eventId: this.$route.params.eventId,
        rsvp,
        userId: person.id,
      }).then(() => {
        this.$emit('updateAttendees');
      });
    },
    handleOnListFilter(filter) {
      if (filter) {
        this.fetchGroupEventAttendees({ ...this.eventData, filterIsOnMyList: true });
        this.$gtag.event('on_list_filter', {
          event_category: 'members',
          event_label: `${this.$route.params.group} / Filtered by on_list`,
        });
      } else {
        this.fetchGroupEventAttendees(this.eventData);
      }
    },
  },

  computed: {
    rsvps() {
      switch (this.selectedList) {
        case 1:
          return filterRsvps(this.rsvpYesList, this.searchTerm);
        case 2:
          return filterRsvps(this.rsvpNoList, this.searchTerm);
        case 3:
          return filterRsvps(this.rsvpUnknownList, this.searchTerm);
        default:
          return filterRsvps(this.rsvpYesList, this.searchTerm);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  overflow-wrap: break-word;
}

.modal {
  left: 50%;
  transform: translateX(-50%);
}

.close-icon {
  &:hover path {
    @apply stroke-primary;
  }
  path {
    stroke: #c8cacd;
  }
}

.tab-active::after {
  content: '';
  @apply absolute bottom-0 h-1 left-0 right-0 bg-primary;
}
</style>
